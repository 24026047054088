<template>
  <Header />
  <Timer />
  <MainBanner />
  <div v-if="picks" class="content content-wrapper content-yp" v-html="this.$store.state.options.staticContent.trYourPicksContent"></div>
  <div v-if="picks === false" class="content content-wrapper content-yp" v-html="this.$store.state.options.staticContent.trYourPicksContentAfter"></div>
  <Loader class="small" v-if="loading"/>
  <div v-if="!loading && picks" v-html="picks" class="your-picks-list"></div>
</template>

<script>
import Timer from '@/components/Timer.vue';
import MainBanner from '@/components/MainBanner.vue';

export default {
  name: 'YourPicks',
  data() {
    return {
      picks: null,
      loading: true,
    };
  },
  components: {
    Timer,
    MainBanner,
  },
  methods: {
    async getUserPicks() {
      try {
        const response = await this.axios.get(`${process.env.VUE_APP_API}/read/v1/entry/${this.$route.params.id}`);
        this.picks = response.data;
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    this.getUserPicks();
    // console.log(this.$route);
  },
};
</script>

<style lang="scss">
.content-yp {
  text-align: center;
  padding: 1rem 1rem .5rem 1rem;
}
.your-picks-list {
  font-weight: 700;
  text-align: center;
  background: #efefef;
  padding: .5rem 0;
  font-size: 16px;
  margin-bottom: 1rem;
  color: #4a4a4a;
  li {
    list-style: none;
    display: flex;
    justify-content: center;
  }
  strong {
    width: 80px;
  }
  span {
    flex: 1;
    &:first-child {
      text-align: right;
    }
    &:last-child {
      text-align: left;
    }
  }
}
.question-row {
  margin-bottom: .25rem;
  font-size: 18px;
  font-weight: 400;
}
.answer-row {
  font-size: 16px;
}
.answer-row:not(:last-child) {
  font-weight: 700;
  margin-bottom: 1rem;
}
</style>
